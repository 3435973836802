export function getQueryParams<T = Partial<Record<string, string>>>(
  location: Location
): T {
  const search = location.search.substring(1);

  return search
    ? JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    : {};
}
