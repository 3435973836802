import { ValidationRule } from 'react-hook-form';

export const emailValidation: ValidationRule<RegExp> = {
  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  message: 'Die eingegebene Adresse ist ungültig.',
};

export const requiredValidation: ValidationRule<boolean> = {
  message: 'Dies ist eine Pflichtangabe.',
  value: true,
};
