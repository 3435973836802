import clsx from 'clsx';
import React from 'react';
import { Icons } from '../icon/Icons';

export function PasswortRequirementsHint({
  className,
}: {
  className?: string;
}) {
  return (
    <div
      className={clsx(
        'text-13 border-default bg-star bg-opacity-20 rounded-lg p-4 flex',
        className
      )}>
      <Icons.Danger className="inline-block mr-4" />
      <div>
        <span className="font-semibold mb-2 inline-block">
          Passwortanforderungen:
        </span>
        <ul className="ml-8 list-disc">
          <li>mindestens 8 Zeichen</li>
          <li>Gross-/Kleinschreibung</li>
          <li>mindestens eine Zahl</li>
          <li>mindestens ein Sonderzeichen</li>
        </ul>
      </div>
    </div>
  );
}
