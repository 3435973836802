// custom endpoints see magento backend repo -> app/code/SuS/Customer
const SHIPPING_QUOTE_PATH = '/V1/sus/shippingQuote';
const ACTIVATE_ACCOUNT_PATH = '/V1/sus/customers/activate';
const RESEND_ACCOUNT_CONFIRM_PATH = '/V1/customers/confirm';

const REQUEST_RESET_PASSWORD_PATH = '/V1/customers/password';
const RESET_PASSWORD_PATH = '/V1/customers/resetPassword';
const ADDRESS_VALIDATION_PATH = '/V1/vertex-address-validation/vertex-address';

const getCheckResetPasswordTokenPath = (
  customerId: string,
  resetPasswordLinkToken: string
) =>
  `/V1/customers/${customerId}/password/resetLinkToken/${resetPasswordLinkToken}`;

const buildUrl = (context: string) =>
  `${process.env.GATSBY_MAGENTO_REST_URI}${context}`;

const dispatchRequest = (input: RequestInfo, init?: RequestInit) =>
  fetch(input, init).then(result => {
    return result.ok ? result?.json?.() : Promise.reject(result);
  });

const resendConfirmEmail = (email: string, websiteId: string) =>
  dispatchRequest(buildUrl(RESEND_ACCOUNT_CONFIRM_PATH), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      websiteId,
    }),
  });

const activateAccount = (id: string, confirmationKey: string) =>
  dispatchRequest(buildUrl(ACTIVATE_ACCOUNT_PATH), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      confirmationKey,
      customer_id: id,
    }),
  });

const sendShippingQuote = (cartId: string) =>
  dispatchRequest(buildUrl(SHIPPING_QUOTE_PATH), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cartId,
    }),
  });

const requestPasswordReset = (email: string) =>
  dispatchRequest(buildUrl(REQUEST_RESET_PASSWORD_PATH), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      template: 'email_reset',
    }),
  });

const checkPasswordResetToken = (customerId: string, token: string) =>
  dispatchRequest(buildUrl(getCheckResetPasswordTokenPath(customerId, token)));

const resetPassword = (
  email: string,
  newPassword: string,
  resetToken: string
) =>
  dispatchRequest(buildUrl(RESET_PASSWORD_PATH), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      newPassword,
      resetToken,
    }),
  });

export interface AddressInterface {}

const validateAddress = (address: AddressInterface) =>
  dispatchRequest(buildUrl(ADDRESS_VALIDATION_PATH), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      address,
    }),
  });

export const restApi = {
  activateAccount,
  sendShippingQuote,
  requestPasswordReset,
  checkPasswordResetToken,
  resetPassword,
  resendConfirmEmail,
  validateAddress,
};
