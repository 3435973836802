import React, { useEffect, useState } from 'react';
import Layout from '@sus-core/components/scaffolding/Layout';
import { Redirect, Router, useLocation } from '@reach/router';
import { Route } from '@sus-core/router/Route';
import { getQueryParams } from '@sus-core/utils/getQueryParams';
import { restApi } from '@sus-core/api/client/sus-rest-client';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import {
  ResetPasswordForm,
  ResetPasswordFormProps,
} from '@sus-core/components/user/ResetPasswordForm';
import { notifyError, notifySuccess } from '@sus-core/utils/notify';

import { Link } from 'gatsby';
import { Form, FormProps } from '@sus-core/components/form/Form';
import { FormField } from '@sus-core/components/form/FormField';
import { Button } from '@sus-core/components/button/Button';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';

// confirm account page

// password reset?! wenn auf passwort zurücksetzten link aus mail geklicked wird

// sonstige seiten aus mails?! welche?

/**
https://dev.schrank-und-stuhl.de/customer/account/createPassword/?id=31464&token=vTv3WzEeA02CcBe9jLRI3vWhglNaNBO0

https://dev.schrank-und-stuhl.de/customer/account/createPassword/?token=btpwCZEn5lavWcSuglMVLgpfBawdkY0G

https://localhost:8000/customer/account/confirm/?back_url=&id=31464&key=ABpSKnkbElRVbyH1xce6J6vrKB0YLBZC
 */

export default function CustomerIndex({ location, pageContext }: SusPageProps) {
  return (
    <PageProvider
      data={{
        pageType: 'Login',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        location={location}
        meta={{ title: 'Schrank und Stuhl - Benutzerkonto' }}>
        <div className={PAGE_CONTAINER_CSS}>
          <Router basepath="/customer">
            <Redirect default from="account" to="/user/overview" noThrow />

            <Route path="account/confirm/*" render={props => <ConfirmPage />} />

            <Route
              path="account/createPassword/*"
              render={() => <CreatePasswordPage />}
            />
          </Router>
        </div>
      </Layout>
    </PageProvider>
  );
}

function ConfirmPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isActivated, setActivated] = useState<boolean>(false);
  const location = useLocation();
  const { key, id } = getQueryParams<{ key?: string; id?: string }>(location);

  useEffect(() => {
    if (key && id) {
      setLoading(true);
      restApi
        .activateAccount(id, key)
        .then(result => {
          setActivated(true);
        })
        .catch(e => {
          setError('Konto konnte nicht aktiviert werden. ');
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <section className="sm:w-2/3 mx-auto b-box">
      {loading && (
        <>
          <p>Konto wird aktiviert...</p>
        </>
      )}
      {error && (
        <>
          <p className="text-center">{error}</p>
          {/* <ConfirmAccountForm /> */}
        </>
      )}
      {isActivated && (
        <div>
          <h3>Ihr Konto wurde aktiviert.</h3>
          <p>
            Sie können sich nun <Link to="/anmelden">anmelden</Link>.
          </p>
        </div>
      )}
    </section>
  );
}

function ConfirmAccountForm() {
  const handleSubmit: FormProps['onSubmit'] = ({ email }) => {
    restApi
      .resendConfirmEmail(email, 'sus')
      .then(() =>
        notifySuccess(
          'Anfrage erfolgreich. Sie sollten in den nächsten Minuten eine E-Mail erhalten.'
        )
      );
  };
  return (
    <Form onSubmit={handleSubmit} className="flex flex-col">
      <h4>Neuen Bestätigungslink anfordern</h4>
      <p>
        Bitte geben Sie Ihre Email Adresse ein, Sie erhalten im Anschluss einen
        neuen Bestätigungslink.
      </p>
      <FormField name="email" label="E-Mail Adresse">
        <input type="email" required />
      </FormField>
      <Button size={2} variant="primary" className="mt-4 ml-auto">
        Senden
      </Button>
    </Form>
  );
}

function CreatePasswordPage() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { token } = getQueryParams<{ token?: string }>(location);

  const handleSubmit: ResetPasswordFormProps['onSubmit'] = ({
    email,
    password,
  }) => {
    setLoading(true);
    restApi
      .resetPassword(email, password, token)
      .then(() => {
        notifySuccess('Ihr neues Passwort wurde gespeichert.', 3000);
        setSuccess(true);
      })
      .catch(() =>
        notifyError('Beim Speichern des Passwortes ist ein Fehler aufgetreten.')
      )
      .finally(() => setLoading(false));
  };
  return (
    <section className="sm:w-2/3 mx-auto b-box">
      {!success && (
        <>
          <h3>Neues Passwort</h3>
          <p>Bitte geben Sie Ihr neues Passwort ein.</p>
          <ResetPasswordForm loading={loading} onSubmit={handleSubmit} />
        </>
      )}

      {success && (
        <>
          <h3>Neues Passwort gespeichert!</h3>

          <Link to="/anmelden">Zur Anmeldung</Link>
        </>
      )}
    </section>
  );
}
