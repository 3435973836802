import { ComponentType, ReactNode } from 'react';
import { RouteComponentProps } from '@reach/router';

import React from 'react';

export interface RouteProps extends RouteComponentProps {
  exact?: boolean;
  component?: ComponentType<any>;
  render?: (props: any) => ReactNode;
  children?: ReactNode;
}

export const Route = ({
  children,
  component: Component = React.Fragment,
  render,
  ...rest
}: RouteProps) => {
  if (render) {
    return <>{render({ children, ...rest })}</>;
  }
  return <Component {...rest}>{children}</Component>;
};
