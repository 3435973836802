// https://dev.schrank-und-stuhl.de/customer/account/createPassword/?token=btpwCZEn5lavWcSuglMVLgpfBawdkY0G

import clsx from 'clsx';
import React from 'react';
import { Button } from '../button/Button';
import { Form } from '../form/Form';
import { FormField } from '../form/FormField';
import { requiredValidation } from '../form/validationRules';
import { PasswortRequirementsHint } from './PasswortRequirementsHint';

export type ResetPasswordFormProps = {
  className?: string;
  loading?: boolean;
  onSubmit?: (params: { email: string; password: string }) => void;
};

export function ResetPasswordForm({
  className,
  onSubmit,
  loading,
}: ResetPasswordFormProps) {
  return (
    <Form
      className={clsx('gap-y-2 flex flex-col', className)}
      onSubmit={({ email, password }) => {
        onSubmit?.({ email, password });
      }}
      render={({ formState }) => {
        const { errors, isValid, isDirty } = formState;
        return (
          <>
            <FormField name="email" label="E-Mail" labelClass="">
              <input className="flex-1" type="email" required />
            </FormField>
            <FormField
              name="password"
              label="Passwort"
              className="mb-6"
              required={requiredValidation}
              minLength={8}
              error={
                errors['password'] &&
                `Pflichtfeld: Mind. 8 Zeichen Länge, Gross-/Kleinschreibung, mind. eine Zahl sowie mind. ein Sonderzeichen`
              }>
              <input
                autoComplete="off"
                className="flex-1"
                type="password"
                // ref={register({ required: true, minLength: 8 })}
                minLength={8}
                required
              />
            </FormField>
            <PasswortRequirementsHint />
            <Button
              loading={loading}
              disabled={!isDirty || !isValid}
              type="submit"
              variant="primary"
              size={2}>
              Senden
            </Button>
          </>
        );
      }}
    />
  );
}
